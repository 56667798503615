.c-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  @include mq(pc) {
    background: #ffffff;
  }
  &__inner {
    @include mq(pc) {
      padding: 30px 10px 50px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      max-width: 1360px;
      transition: $transition;
    }
  }
  &-nav {
    @include mq(pc) {
      display: flex;
      @at-root {
        .c-header.is-scroll {
          .c-header__inner {
            @include mq(pc) {
              padding: 10px 10px 30px;
            }
          }
          .elem-free {
            @include mq(pc) {
              top: 53px;
            }            
          }
        }
      }      
    }
    @include mq(sp) {
      position: absolute;
      text-align: center;
      font-size: 2.8rem;
      padding-bottom: 60 / $spDesign + vw;
      box-shadow: 0px 3px 3px -3px rgba(0,0,0,0.1);
      left: 100%;
      transition: $transition * 2;
      width: 100%;
      background: #ffffff;
      @at-root {
        .c-header.is-open {
          .c-header-nav {
            left: 0;
          }
        }
      }
    }
    &-menu {
      @include mq(pc) {
        display: flex;
      }
      &__link {
        padding: 13px 10px 13px 10px;
        @include mq(pc) {
          margin: 0 10px;
          border-bottom: 2px solid #fff;
          &:hover {
            border-bottom: 2px solid $colorAccentGreen;
          }
          &.is-active {
            border-bottom: 2px solid $colorAccentGreen;
          }
        }
        @include mq(sp) {
          display: block;
          padding: 24 / $spDesign + vw 0;
        }
      }      
    }
    &-list {
      &__insta {
        display: block;
        @include mq(pc) {
          width: 30px;
          height: 30px;
          margin-left: 30px;
        }
        @include mq(sp) {
          width: 70 / $spDesign + vw;
          height: 70 / $spDesign + vw;
          margin: 0 auto;
        }
      }
    }
  }
  &-info {
    @include mq(sp) {
      display: flex;
      padding: 2.6vw;
      align-items: center;
      background: #ffffff;
      position: relative;
    }
    &__logo {
    @include mq(sp) {
      width: 475 / $spDesign + vw;
      margin-right: 50 / $spDesign + vw;
      }
    }
    &__btn {
      width: 55 / $spDesign + vw;
      height: 30 / $spDesign + vw;
      margin-left: 40 / $spDesign + vw;
      top: -6px;
      position: relative;
      i {
        background: #000;
        width: 100%;
        height: 3 / $spDesign + vw;
        display: block;
        position: relative;
        left: 0;
        bottom: -16 / $spDesign + vw;
        transition: $transition;
        &:before,&:after {
          content:"";
          position: absolute;
          display: block;
          background: #000;
          left: 0;
          width: 100%;
          height: 3 / $spDesign + vw;
          transition: $transition;
        }
        &:before {
          transform: translateY(1.8vw);
        }
        &:after {
          transform: translateY(-1.8vw);
        }
        @at-root {
          .c-header.is-open {
            .c-header-info__btn {
              i {
                background: #fff;
                &:before {
                  transform: rotate(45deg);
                }
                &:after {
                  transform: rotate(-45deg);
                }
              }
            }
          }
        }
      }
    }
    &__txt {
      position: relative;
      padding-top: 4vw;
      font-size: 1.8rem;
    }
  }
}

.elem-free {
  font-size: 1.6rem;
  @include mq(pc) {
    right: -10px;
    position: absolute;
    top: 93px;
    right: 0;
    &:hover {
      img {
        transform: translateY(10px);
      }
    }
  }
  @include mq(sp) {
    background: $colorPrimary;
    color: #fff;
    width: 77 / $spDesign + vw;
    height: 77 / $spDesign + vw;
  }
  &__inner {
    @include mq(pc) {
      position: relative;
      width: 160px;
      height: 170px;
      transform: rotate(-45deg);
      &:before {
        content:"";
        position: absolute;
        width: 120px;
        height: 120px;
        background: $colorPrimary;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }      
    }
    @include mq(sp) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  &__txt {
    @include mq(pc) {
      display: block;
      position: absolute;
      color: #ffffff;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
      width: 100%;
      text-align: center;
      font-size: 1.6rem;
      img {
        display: block;
        margin: 5px auto 0;
        width: 23px;
        height: 23px;
        transition: $transition;
      }      
    }
    @include mq(sp) {
      line-height: 1.08;
      font-size: 2.4rem;
    }

  }
}