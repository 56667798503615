.c-ttl {
  font-size: 3.6rem;
  margin-bottom: 50px;
  text-align: center;
  color: $colorAccent;
  line-height: 1;
  @include mq(sp) {
    font-size: 4.8rem;
    margin-bottom: 30 / $spDesign + vw;
    line-height: 1.3;
  }
  &--en {
    display: block;
    font-size: 2.2rem;
    @include mq(pc) {
      margin-top: 15px;
    }
    @include mq(sp) {
      font-size: 3.2rem;
    }
  }
  &-sub {
    color: $colorAccent;
    font-size: 2.2rem;
    line-height: 1.45;
    @include mq(sp) {
      font-size: 3.4rem;
    }
    & + p {
      margin-top: 18px;
      @include mq(sp) {
        margin-top: 20 / $spDesign + vw;
      }
    }
    &:nth-child(n+2) {
      margin-top: 30px;
      @include mq(sp) {
        margin-top: 50 / $spDesign + vw;
      }    
    }
  }
  &-primary-en {
    text-align: center;
    font-size: 4.8rem;
    font-family: 'Tangerine', cursive;
    color: $colorAccent;
    @include mq(sp) {
      font-size: 6.2rem;
    }
  }
}