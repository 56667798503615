@font-face {
  font-family: 'Noto Serif JP';
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/NotoSerifJP-Regular.otf") format("opentype");
}
@font-face {
  font-family: 'Tangerine';
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Tangerine-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Vollkorn';
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Vollkorn-Regular.ttf") format("truetype");
}